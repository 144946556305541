<template>
    <PointBase :loader_prop="loader_prop">
        <div class="packages_section">
            <div class="container">
                <div class="customer_header rtl_div">
                    <div class="left_wrap">
                        <h4>חבילות</h4>
                    </div>
                    <div class="right_wrap">
                        <router-link to="/point/point_new_package" class="comman_anchor"><img src="images/package_icon.svg" alt="">דווח על קבלת חבילה חדשה</router-link>
                    </div>
                </div>
                <div class="packages_box_wrap row">
                    <div class="packages_box" v-for="package_user in view_data" :key="package_user.id">
                        <div class="packages_detail">
                            <div class="packages_profile">
                                <div class="packages_name">
                                    <div class="packages_image">
                                        <img src="images/package_box.svg" alt="">
                                    </div>
                                    <h6>{{package_user.package_number}} :מס׳ חבילה</h6>
                                </div>
                                <div>
                                    <span>{{package_user.ts_date}} :תאריך קבלת החבילה</span>
                                    <span>{{package_user.ts_time}} :שעת קבלת החבילה</span>
                                </div>
                            </div>
                            <div class="" v-if = "package_user.status == 'collected'">
                                <a href="" class="comman_btn success_btn">התקבל</a>
                            </div>
                            <div class="" v-if = "package_user.status == 'waiting'">
                                <a href="" class="comman_btn reject_btn">טרם נאסף</a>
                            </div>
                        </div>
                        <div class="packages_info">
                            <p><strong>שם הלקוח : </strong> {{package_user.name}}</p>
                            <p><strong>מס׳ טלפון : </strong>{{package_user.phone_number}}</p>
                            <p><strong>מס׳ תיבה : </strong>{{package_user.box_number}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </PointBase>
</template>

<script>
import BackendModel from "../../models/BackendModel";
import PointBase from "./PointBase";
import('./point/css/style.css')

export default {
  name: 'PointPackages',
  components:{
        PointBase
    },
  data() {
    return {
        view_data : [],
        loader_prop: true
    }
  },
   async mounted () {
    let backendModel = new BackendModel()
    let view_data = await backendModel.point_Request("/Api/service/backoffice/point_get_all_packages",{})
    this.view_data = view_data.data.view_data
    this.loader_prop = false
   },       
}
</script>

<style>
@import 'point/css/style.css';
</style>
<style>
.rtl_div {
  direction: RTL;
}
</style>